export const ICON_RESIDENCE = "home-lg-alt";
export const ICON_SALES_MEETING = "briefcase";
export const ICON_OVERVIEW = "user";
export const ICON_START = "home-lg-alt";
export const ICON_OBJECT_CONNECTIONS = "home-lg-alt";
export const ICON_TIMELINE = "clock";
export const ICON_LEADS = "street-view";
export const ICON_KPI = "chart-line";
export const ICON_NPS = "smile";
export const ICON_OUTOING_MESSAGES = "envelope";
export const ICON_SENT_TIPS = "lightbulb";
export const ICON_TASKS = "check";
export const ICON_CONTACTS = "user-friends";
export const ICON_SHOWINGS = "users";
export const ICON_CLIENT_CONNECTIONS = "users";
export const ICON_NOTES = "file";
export const ICON_MESSAGES = "envelope-open";
export const ICON_LEAD_SCORING = "star";
export const ICON_CALLING_LISTS = "list";
export const ICON_TOP_LISTS = "list-ol";
export const ICON_SURVEYS = "smile";
export const ICON_CONSENTS = "user-shield";
export const ICON_SEARCH_PROFILE = "search";
export const ICON_TIPS = "lightbulb";
export const ICON_GROWTH_REPORT = "rocket";
export const ICON_EMAIL_REPORT = "envelopes-bulk";
export const ICON_MARKETING_REPORTS = "rectangle-ad";
export const ICON_BUDGET = "calculator-alt";
export const ICON_INVOICING = "credit-card";
export const ICON_EXTERNAL_TIPS = "lightbulb";
export const ICON_VALUE_MONITOR = "comment-alt-dollar";
export const ICON_OBJECT_OVERVIEW = "home-lg-alt";
export const ICON_POTENTIAL_BUYERS = "users";
export const ICON_SHOWINGS_PAGE = "house-return";
export const ICON_MATCHINGS = "link";
export const ICON_FOLLOWERS = "users-rectangle";
export const ICON_STATISTICS = "chart-line";
