import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { CampaignsSubpageService } from "@app/campaigns/campaigns-subpage-service";
import { ListsSubPageService } from "@app/lists/lists-sub-page.service";
import { SubPage } from "@app/shared-features/mobile-navigation/ngrx/mobile-navigation.reducer";
import { ENTERING_DURATION, EXITING_DURATION } from "@app/shared/animations";
import * as fromConfig from "@app/shared/config/config.reducer";
import { Page } from "@app/shared/config/models";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "@app/shared/utils/roles";
import { StatisticsSubPageService } from "@app/statistics/services/statistics-sub-page.service";
import { select, Store } from "@ngrx/store";
import * as fromUser from "app/shared/user/user.selectors";
import {
  BehaviorSubject,
  map,
  Observable,
  Subject,
  takeUntil,
  tap,
  withLatestFrom,
} from "rxjs";
import * as navbarActions from "./ngrx/navbar.actions";
import * as fromNavbar from "./ngrx/navbar.reducer";

@Component({
  selector: "navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("isVisibleChanged", [
      state("void", style({ width: "0" })),
      state("0", style({ width: "0" })),
      state("1", style({ width: "6rem" })),
      transition("0 => 1", animate(`${ENTERING_DURATION} ease-in`)),
      transition("1 => 0", animate(`${EXITING_DURATION} ease-out`)),
      transition("void => 1", animate(`${ENTERING_DURATION} ease-in`)),
    ]),
  ],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() isNavbarVisible = false;
  @Input() isLargeScreen = false;
  @Input() isMobile = false;
  @Input() isSupportWidgetEnabled: boolean;

  isShowSubNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pages$: Observable<Page[]>;
  subPages$: BehaviorSubject<Record<string, SubPage[]>> = new BehaviorSubject<
    Record<string, SubPage[]>
  >({});
  unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private listsSubPageService: ListsSubPageService,
    private statisticsSubPageService: StatisticsSubPageService,
    private campaignsSubpageService: CampaignsSubpageService
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(fromNavbar.getNavbarStatus))
      .subscribe((navbarVisibility) => {
        if (!navbarVisibility) this.isShowSubNav$.next(false);
      });

    this.subScribeSubPages();

    this.pages$ = this.store.pipe(
      select(fromConfig.getAppPages()),
      map((pages: Page[]) =>
        pages.filter((page: Page) => page.enabled && page.visible)
      ),
      tap((pages) => {
        pages.forEach((page) => {
          this.loadSubPages(page.label);
        });
      })
    );

    this.subPages$.subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener("document:click")
  clickedOut() {
    this.isShowSubNav$.next(false);
  }

  onClick(): void {
    this.isShowSubNav$.next(false);
    setTimeout(() => {
      if (!this.isLargeScreen) {
        this.store.dispatch(navbarActions.hide());
      }
    }, 150);
  }

  isAuthorized(): Observable<boolean> {
    return this.store.pipe(
      select(fromUser.hasRoles([ROLE_SUPER_ADMIN, ROLE_ADMIN]))
    );
  }

  openSupportWidget(): void {
    // @ts-ignore
    if (!!window.FreshworksWidget) {
      // @ts-ignore
      window.FreshworksWidget("open");
    }
  }

  getIcon(label: string) {
    switch (label) {
      case "dashboard":
        return "tachometer-alt-fastest";
      case "showings":
        return "memo-circle-info";
      case "lists":
        return "list";
      case "objects":
        return "home-lg-alt";
      case "statistics":
        return "analytics";
      case "competitions":
        return "trophy-alt";
      case "campaigns":
        return "paper-plane";
      case "settings":
        return "cog";
      default:
        return "check";
    }
  }

  getSubPages(label: string): SubPage[] {
    return this.subPages$.value[label];
  }

  showSubNav(event: Event, label: string) {
    event.preventDefault();
    this.loadSubPages(label);
    this.isShowSubNav$.next(true);
  }

  private loadSubPages(label: string) {
    switch (label) {
      case "lists":
        this.listsSubPageService.loadSubPages();
        break;
      case "statistics":
        this.statisticsSubPageService.loadSubPages();
        break;
      case "campaigns":
        this.campaignsSubpageService.loadSubPages();
        break;
      default:
        break;
    }
  }

  checkHasSubLinks(label: string) {
    switch (label) {
      case "lists":
      case "statistics":
      case "campaigns":
        return true;
      default:
        return false;
    }
  }

  private subScribeSubPages() {
    this.listsSubPageService.subPages$
      .pipe(
        takeUntil(this.unsubscribe$),
        withLatestFrom(this.subPages$),
        map(([listSubPages, allSubPages]) => {
          const newSubPages = {
            ...allSubPages,
            lists: listSubPages,
          };
          this.subPages$.next(newSubPages);
        })
      )
      .subscribe();

    this.statisticsSubPageService.subPages$
      .pipe(
        takeUntil(this.unsubscribe$),
        withLatestFrom(this.subPages$),
        map(([statisticsSubPages, allSubPages]) => {
          const newSubPages = {
            ...allSubPages,
            statistics: statisticsSubPages,
          };
          this.subPages$.next(newSubPages);
        })
      )
      .subscribe();

    this.campaignsSubpageService.subPages$
      .pipe(
        takeUntil(this.unsubscribe$),
        withLatestFrom(this.subPages$),
        map(([campaignsSubPages, allSubPages]) => {
          const newSubPages = {
            ...allSubPages,
            campaigns: campaignsSubPages,
          };
          this.subPages$.next(newSubPages);
        })
      )
      .subscribe();
  }
}
