<div
  id="fab-dismiss"
  *ngIf="fabTogglerState === 'active'"
  (click)="onToggleFab()"
></div>
<div class="fab-container">
  <button
    mat-fab
    class="fab-toggler"
    [class.active]="fabTogglerState === 'active'"
    (click)="onToggleFab()"
  >
    <app-icon
      name="bars-filter"
      size="medium"
      [@fabToggler]="{ value: fabTogglerState }"
    ></app-icon>
  </button>
  <div [@floatButtonStagger]="buttons.length">
    <button
      *ngFor="let btn of buttons"
      mat-mini-fab
      class="fab-secondary"
      color="secondary"
      matTooltip="{{ btn.label | translate }}"
      matTooltipClass="q-tooltip"
      matTooltipPosition="before"
      [class.selected]="selectedButton === btn.label"
      (click)="handleClick(btn)"
    >
      <app-icon [name]="btn.icon"></app-icon>
    </button>
  </div>
</div>
