import { Polygon, Position } from "geojson";

export function focusAreaFormatParameters(
  officeIds: string[] | null,
  agentIds: string[] | null,
  entityType: "office" | "agent" | null
): Record<string, unknown> {
  const params = { nestedFilters: [] };

  // Handle the case where no selection is made.
  if (
    !entityType &&
    (!officeIds || officeIds.length === 0) &&
    (!agentIds || agentIds.length === 0)
  ) {
    return params;
  }

  // Handle the case where no entity type is specified.
  if (!entityType) {
    if (officeIds && officeIds.length > 0) {
      params.nestedFilters.push({
        key: "belongsToOffice",
        filters: [
          {
            field: "id",
            operator: "anyOf",
            value: officeIds,
          },
        ],
      });
    }
    if (agentIds && agentIds.length > 0) {
      params.nestedFilters.push({
        key: "belongsToAgent",
        filters: [
          {
            field: "id",
            operator: "anyOf",
            value: agentIds,
          },
        ],
      });
    }
  } else {
    // Handle the case where an entity type is specified.
    if (entityType === "office") {
      if (!officeIds || officeIds.length === 0) {
        params.nestedFilters.push({
          key: "belongsToOffice",
          filters: [
            {
              field: "id",
              operator: "notEmpty",
            },
          ],
        });
      } else {
        params.nestedFilters.push({
          key: "belongsToOffice",
          filters: [
            {
              field: "id",
              operator: "anyOf",
              value: officeIds,
            },
          ],
        });
      }

      params.nestedFilters.push({
        key: "belongsToAgent",
        filters: [
          {
            field: "id",
            operator: "empty",
          },
        ],
      });
    } else if (entityType === "agent") {
      if (!agentIds || agentIds.length === 0) {
        params.nestedFilters.push({
          key: "belongsToAgent",
          filters: [
            {
              field: "id",
              operator: "notEmpty",
            },
          ],
        });

        if (officeIds && officeIds.length > 0) {
          params.nestedFilters.push({
            key: "belongsToOffice",
            filters: [{ field: "id", operator: "anyOf", value: officeIds }],
          });
        }
      } else {
        params.nestedFilters.push({
          key: "belongsToAgent",
          filters: [
            {
              field: "id",
              operator: "anyOf",
              value: agentIds,
            },
          ],
        });
      }
    }
  }

  return params;
}

// Function to calculate the signed area of a ring
function calculateSignedArea(ring: Position[]): number {
  let area = 0;
  const n = ring.length;

  for (let i = 0; i < n - 1; i++) {
    const [x1, y1] = ring[i];
    const [x2, y2] = ring[i + 1];
    area += (x2 - x1) * (y2 + y1);
  }

  return area / 2;
}

// Function to ensure the coordinates of the polygon are ordered clockwise
export function ensureClockwise(polygon: Polygon): Polygon {
  const adjustedCoordinates = polygon.coordinates.map((ring) => {
    const area = calculateSignedArea(ring);
    // If the area is positive, the ring is counterclockwise, so reverse it
    return area > 0 ? ring.slice().reverse() : ring;
  });

  return {
    ...polygon,
    coordinates: adjustedCoordinates,
  };
}
