import {
  statisticsBudgetReducer,
  StatisticsBudgetState,
} from "@app/statistics/statistics-budget/ngrx/statistics-budget.reducer";
import {
  statisticsExternalBankTipsReducer,
  StatisticsExternalBankTipsState,
} from "@app/statistics/statistics-external-bank-tips/ngrx/statistics-external-bank-tips.reducer";
import {
  statisticsGrowthReducer,
  StatisticsGrowthState,
} from "@app/statistics/statistics-growth/ngrx/statistics-growth.reducer";
import {
  statisticsInvoicingReducer,
  StatisticsInvoicingState,
} from "@app/statistics/statistics-invoicing/ngrx/statistics-invoicing.reducer";
import {
  statisticsKpiReducer,
  StatisticsKpiState,
} from "@app/statistics/statistics-kpi/ngrx/statistics-kpi.reducer";
import {
  leadStatisticsReducer,
  LeadStatisticsState,
} from "@app/statistics/statistics-leads-new/ngrx/statistics-leads-new.reducer";
import {
  leadResultsReducer,
  LeadResultsState,
  leadSourcesReducer,
  LeadSourcesState,
} from "@app/statistics/statistics-leads/ngrx/statistics-leads.reducer";
import {
  statisticsNpsReducer,
  StatisticsNpsState,
} from "@app/statistics/statistics-nps/ngrx/statistics-nps.reducer";
import {
  statisticsSentTipsReducer,
  StatisticsSentTipsState,
} from "@app/statistics/statistics-sent-tips/ngrx/statistics-sent-tips.reducer";
import {
  statisticsStartPageReducer,
  StatisticsStartPageState,
} from "@app/statistics/statistics-start/ngrx/statistics-start.reducer";
import {
  statisticsTopListsReducer,
  StatisticsTopListsState,
} from "@app/statistics/statistics-top-lists/ngrx/statistics-top-lists.reducer";
import { combineReducers } from "@ngrx/store";
import { employeesReducer, EmployeesState } from "./reducers/employees.reducer";
import { filterReducer, FilterState } from "./reducers/filters.reducer";
import {
  SalesMeetingBookedState,
  SalesMeetingResultsState,
  salesMeetingSourcesReducer,
  SalesMeetingSourcesState,
  statisticsSalesMeetingReducerBooked,
  statisticsSalesMeetingReducerResults,
} from "./statistics-sales-meetings/ngrx/statistics-sales-meeting.reducer";
import {
  StatisticsEmailEventsReducer,
  StatisticsEmailEventsState,
} from "@app/statistics/statistics-email-events/ngrx/statistics-email-events.reducer";

export interface StatisticsModuleState {
  filters: FilterState;
  employees: EmployeesState;
  salesMeetingResults: SalesMeetingResultsState;
  salesMeetingBooked: SalesMeetingBookedState;
  salesMeetingSources: SalesMeetingSourcesState;
  leadSources: LeadSourcesState;
  leadResults: LeadResultsState;
  leadStatistics: LeadStatisticsState;
  kpi: StatisticsKpiState;
  budget: StatisticsBudgetState;
  invoicing: StatisticsInvoicingState;
  nps: StatisticsNpsState;
  sentTips: StatisticsSentTipsState;
  topLists: StatisticsTopListsState;
  startPage: StatisticsStartPageState;
  growth: StatisticsGrowthState;
  externalBankTips: StatisticsExternalBankTipsState;
  emailEvents: StatisticsEmailEventsState;
}

const reducers = {
  filters: filterReducer,
  employees: employeesReducer,
  salesMeetingResults: statisticsSalesMeetingReducerResults,
  salesMeetingBooked: statisticsSalesMeetingReducerBooked,
  salesMeetingSources: salesMeetingSourcesReducer,
  leadSources: leadSourcesReducer,
  leadResults: leadResultsReducer,
  leadStatistics: leadStatisticsReducer,
  kpi: statisticsKpiReducer,
  budget: statisticsBudgetReducer,
  invoicing: statisticsInvoicingReducer,
  nps: statisticsNpsReducer,
  sentTips: statisticsSentTipsReducer,
  topLists: statisticsTopListsReducer,
  startPage: statisticsStartPageReducer,
  growth: statisticsGrowthReducer,
  externalBankTips: statisticsExternalBankTipsReducer,
  emailEvents: StatisticsEmailEventsReducer,
};

export function statisticsModuleReducer(state: any, action: any) {
  return combineReducers(reducers)(state, action);
}
