import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import {
  ConfigService,
  ExternalTipsStatisticsIntegration,
} from "@app/core/services/config/config.service";
import { SubPage } from "@app/shared-features/mobile-navigation/ngrx/mobile-navigation.reducer";
import { InvoicingFeature } from "@app/shared/config/models/invoicing-feature";
import { ValueMonitorFeature } from "@app/shared/config/models/value-monitor-feature";
import {
  BUDGET,
  BUDGET_FIVE_YEAR,
  BUDGET_MONTHLY,
  EMAIL_REPORT,
  EXTERNAL_TIPS_STATISTICS,
  GROWTH_REPORT,
  INVOICING,
  KPI,
  LEADS,
  LEADS_NEW,
  MARKETING_REPORTS,
  NPS,
  SALES_MEETINGS,
  SENT_TIPS,
  START,
  TOP_LISTS,
  VALUE_MONITOR,
} from "@app/shared/config/utils/sub-pages";
import { hasRole, isManagerOrAdmin } from "@app/shared/user";
import { STATISTICS_CRM_MODULE } from "@app/shared/utils/crm-modules";
import {
  ICON_BUDGET,
  ICON_EMAIL_REPORT,
  ICON_EXTERNAL_TIPS,
  ICON_GROWTH_REPORT,
  ICON_INVOICING,
  ICON_KPI,
  ICON_LEADS,
  ICON_MARKETING_REPORTS,
  ICON_NPS,
  ICON_SALES_MEETING,
  ICON_SENT_TIPS,
  ICON_START,
  ICON_TOP_LISTS,
  ICON_VALUE_MONITOR,
} from "@app/shared/utils/icons";
import { ROLE_ADMIN } from "@app/shared/utils/roles";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  combineLatest as observableCombineLatest,
  map,
  Observable,
} from "rxjs";
import * as fromConfig from "../../shared/config/config.reducer";

@Injectable()
export class StatisticsSubPageService {
  _subPages$ = new BehaviorSubject([]);
  isAdminOrManager$: Observable<boolean>;
  isAdmin$: Observable<boolean>;
  isAdminOrManager: boolean;
  isAdmin: boolean;
  invoicingFeature: InvoicingFeature;
  valueMonitorFeature: ValueMonitorFeature;
  externalTipsStatisticsIntegration: ExternalTipsStatisticsIntegration;

  get subPages$(): Observable<SubPage[]> {
    return this._subPages$.asObservable();
  }

  constructor(
    private store: Store<AppState>,
    private configService: ConfigService
  ) {
    this.isAdminOrManager$ = this.store.pipe(select(isManagerOrAdmin));
    this.isAdmin$ = this.store.pipe(select(hasRole(ROLE_ADMIN)));
  }

  loadSubPages(): void {
    observableCombineLatest([
      this.store.pipe(select(fromConfig.getSubPages(STATISTICS_CRM_MODULE))),
      this.store.pipe(select(fromConfig.getFeature(NPS))),
      this.store.pipe(select(fromConfig.getFeature(INVOICING))),
      this.isAdminOrManager$,
      this.isAdmin$,
      this.configService.externalTipsStatisticsIntegration$,
      this.store.pipe(select(fromConfig.getFeature(VALUE_MONITOR))),
    ])
      .pipe(
        map(
          ([
            subPages,
            nps,
            invoicing,
            isAdminOrManager,
            isAdmin,
            externalTipsStatisticsIntegration,
            valueMonitor,
          ]) => ({
            subPages: subPages.filter((p) => p.enabled),
            nps,
            invoicing,
            isAdminOrManager,
            isAdmin,
            externalTipsStatisticsIntegration,
            valueMonitor,
          })
        ),
        map(
          ({
            subPages,
            nps,
            invoicing,
            isAdminOrManager,
            isAdmin,
            externalTipsStatisticsIntegration,
            valueMonitor,
          }) => {
            this.isAdminOrManager = isAdminOrManager;
            this.isAdmin = isAdmin;
            this.invoicingFeature = invoicing;
            this.valueMonitorFeature = valueMonitor;
            this.externalTipsStatisticsIntegration =
              externalTipsStatisticsIntegration;
            return subPages.map((p) => {
              // @ts-ignore
              return p.type === NPS
                ? { ...this.getSubPage(p.type), label: nps.title }
                : { ...this.getSubPage(p.type) };
            });
          }
        )
      )
      .subscribe((pages) => {
        const processedPages = [];
        pages.forEach((page) => {
          if (page.requireAdminOrManager) {
            if (this.isAdminOrManager) {
              if (
                !this.invoicingFeature.onlyVisibleForAdmin ||
                (this.invoicingFeature.onlyVisibleForAdmin &&
                  this.isAdmin &&
                  page.label === INVOICING)
              ) {
                processedPages.push(page);
              }
            }
          } else {
            if (page.label === EXTERNAL_TIPS_STATISTICS) {
              if (
                this.externalTipsStatisticsIntegration.hasExternalTipsStatistics
              ) {
                if (
                  this.externalTipsStatisticsIntegration.integration.toLowerCase() ===
                  "eika"
                ) {
                  page.label = "eika_tips";
                  page.routerLink = this.getRoute(["eika-bank-tips"]);
                  processedPages.push(page);
                }
              }
            } else if (page.label === VALUE_MONITOR) {
              if (!!this.valueMonitorFeature.enabled) {
                processedPages.push(page);
              }
            } else {
              const newPage = {
                ...page,
                subPages: page?.subPages?.filter((sp) => {
                  if (sp.requireAdminOrManager && this.isAdminOrManager) {
                    return true;
                  } else if (!sp.requireAdminOrManager) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              };
              processedPages.push(newPage);
            }
          }
        });
        this._subPages$.next(processedPages);
      });
  }

  getSubPage(type: string): SubPage {
    switch (type) {
      case START: {
        return {
          label: START,
          routerLink: this.getRoute(["start"]),
          icon: ICON_START,
          requireAdminOrManager: false,
        };
      }
      case SALES_MEETINGS: {
        return {
          label: SALES_MEETINGS,
          routerLink: this.getRoute(["sales-meetings"]),
          icon: ICON_SALES_MEETING,
          requireAdminOrManager: false,
        };
      }
      case LEADS: {
        return {
          label: LEADS,
          routerLink: this.getRoute(["leads"]),
          icon: ICON_LEADS,
          requireAdminOrManager: false,
        };
      }
      case LEADS_NEW: {
        return {
          label: LEADS,
          routerLink: this.getRoute(["leads-new"]),
          icon: ICON_LEADS,
          requireAdminOrManager: false,
        };
      }
      case KPI: {
        return {
          label: KPI,
          routerLink: this.getRoute(["kpi"]),
          icon: ICON_KPI,
          requireAdminOrManager: false,
        };
      }
      case NPS: {
        return {
          label: NPS,
          routerLink: this.getRoute(["nps"]),
          icon: ICON_NPS,
          requireAdminOrManager: false,
        };
      }
      case SENT_TIPS: {
        return {
          label: SENT_TIPS,
          routerLink: this.getRoute(["sent-tips"]),
          icon: ICON_SENT_TIPS,
          requireAdminOrManager: false,
        };
      }
      case TOP_LISTS: {
        return {
          label: TOP_LISTS,
          routerLink: this.getRoute(["top-lists"]),
          icon: ICON_TOP_LISTS,
          requireAdminOrManager: false,
        };
      }
      case GROWTH_REPORT: {
        return {
          label: GROWTH_REPORT,
          routerLink: this.getRoute(["growth-report"]),
          icon: ICON_GROWTH_REPORT,
          requireAdminOrManager: false,
        };
      }
      case EMAIL_REPORT: {
        return {
          label: EMAIL_REPORT,
          routerLink: this.getRoute(["email-report"]),
          icon: ICON_EMAIL_REPORT,
          requireAdminOrManager: false,
        };
      }
      case MARKETING_REPORTS: {
        return {
          label: MARKETING_REPORTS,
          routerLink: this.getRoute(["marketing-reports"]),
          icon: ICON_MARKETING_REPORTS,
          requireAdminOrManager: false,
        };
      }
      case BUDGET: {
        return {
          label: BUDGET,
          routerLink: this.getRoute(["budget"]),
          icon: ICON_BUDGET,
          requireAdminOrManager: false,
          subPages: [
            {
              label: "start",
              routerLink: this.getRoute(["budget", "start"]),
              icon: ICON_BUDGET,
            },
            {
              label: BUDGET_MONTHLY,
              routerLink: this.getRoute(["budget", "monthly"]),
              icon: ICON_BUDGET,
              requireAdminOrManager: true,
            },
            {
              label: BUDGET_FIVE_YEAR,
              routerLink: this.getRoute(["budget", "five-year"]),
              icon: ICON_BUDGET,
              requireAdminOrManager: true,
            },
          ],
        };
      }
      case INVOICING: {
        return {
          label: INVOICING,
          routerLink: this.getRoute(["invoicing"]),
          icon: ICON_INVOICING,
          requireAdminOrManager: true,
        };
      }
      case EXTERNAL_TIPS_STATISTICS: {
        return {
          label: EXTERNAL_TIPS_STATISTICS,
          routerLink: this.getRoute(["external-bank-tips"]),
          icon: ICON_EXTERNAL_TIPS,
          requireAdminOrManager: false,
        };
      }
      case VALUE_MONITOR: {
        return {
          label: VALUE_MONITOR,
          routerLink: this.getRoute(["value-monitor"]),
          icon: ICON_VALUE_MONITOR,
          requireAdminOrManager: false,
        };
      }
    }
    return {
      label: "",
      routerLink: ["/crm"],
      icon: "fa-question",
    };
  }

  private getRoute(subRoutes: string[]): any[] {
    return ["/crm", STATISTICS_CRM_MODULE, ...subRoutes];
  }
}
