import { Component, EventEmitter, Input, Output } from "@angular/core";
import { floatButtonAnimations } from "@app/shared/modules/ui-components/float-button/floatButtonAnimations";

@Component({
  selector: "app-float-button",
  templateUrl: "./float-button.component.html",
  styleUrls: ["./float-button.component.scss"],
  animations: floatButtonAnimations,
})
export class FloatButtonComponent {
  @Input() selectedButton: string;
  @Input() fabButtons: { label: string; icon: string }[];
  @Output() clickedButton: EventEmitter<string> = new EventEmitter<string>();

  buttons = [];
  fabTogglerState = "inactive";

  showItems() {
    this.fabTogglerState = "active";
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabTogglerState = "inactive";
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  handleClick(btn: any) {
    this.onToggleFab();
    this.clickedButton?.emit(btn.label);
  }
}
