import * as mobileNavigation from "./mobile-navigation.actions";
import { Action, createReducer, on } from "@ngrx/store";

export interface SubPage {
  label: string;
  routerLink: string[];
  icon: string;
  requireAdminOrManager?: boolean;
  subPages?: SubPage[];
}

export interface MobileNavigationState {
  routes: SubPage[];
}

export const initialState: MobileNavigationState = {
  routes: [],
};

export function mobileNavigationReducer(
  state: MobileNavigationState,
  action: Action
): MobileNavigationState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(mobileNavigation.setRoutes, (state, { subPages }) => ({
    ...state,
    routes: subPages,
  })),
  on(mobileNavigation.reset, () => initialState)
);
