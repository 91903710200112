import { createAction, props } from "@ngrx/store";
import { TypedPaginationListDTO } from "@app/models";
import { Campaign } from "@app/models/campaign";
import { EmailStatisticsFilter } from "@app/statistics/statistics-email-events/models/EmailStatisticsFilter";
import { EmailEventReportResponse } from "@app/statistics/statistics-email-events/models/EmailEventReportResponse";

export const getCampaignsRequest = createAction(
  "[Statistics Email Events] GET_CAMPAIGNS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getCampaignsSuccess = createAction(
  "[Statistics Email Events] GET_CAMPAIGNS_SUCCESS",
  props<{ campaigns: TypedPaginationListDTO<Campaign> }>()
);

export const getCampaignsFail = createAction(
  "[Statistics Email Events] GET_CAMPAIGNS_FAIL"
);

export const getEmailStatisticsRequest = createAction(
  "[Statistics Email Events] GET_STATISTICS_REQUEST",
  props<{ group: string; filters?: EmailStatisticsFilter }>()
);

export const getEmailStatisticsSuccess = createAction(
  "[Statistics Email Events] GET_STATISTICS_SUCCESS",
  props<{ result: EmailEventReportResponse }>()
);

export const getEmailStatisticsFail = createAction(
  "[Statistics Email Events] GET_STATISTICS_FAIL"
);

export const reset = createAction("[Statistics Email Events] RESET");
