import * as actions from "./statistics-email-events.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { StatisticsModuleState } from "@app/statistics/statistics-module.reducer";
import { Campaign } from "@app/models/campaign";
import { EmailEventReportResponse } from "@app/statistics/statistics-email-events/models/EmailEventReportResponse";

export interface StatisticsEmailEventsState {
  campaignsLoading: boolean;
  campaigns: Campaign[];
  reportLoading: boolean;
  report: EmailEventReportResponse;
}

export const initialState: StatisticsEmailEventsState = {
  campaignsLoading: false,
  campaigns: [],
  reportLoading: false,
  report: null,
};

export function StatisticsEmailEventsReducer(
  state: StatisticsEmailEventsState,
  action: Action
): StatisticsEmailEventsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(actions.getCampaignsRequest, (state) => ({
    ...state,
    campaigns: [],
    campaignsLoading: true,
  })),
  on(actions.getCampaignsSuccess, (state, { campaigns }) => ({
    ...state,
    campaignsLoading: false,
    campaigns: campaigns.rows,
  })),
  on(actions.getCampaignsFail, (state) => ({
    ...state,
    campaignsLoading: false,
  })),
  on(actions.getEmailStatisticsRequest, (state) => ({
    ...state,
    reportLoading: true,
    report: null,
  })),
  on(actions.getEmailStatisticsSuccess, (state, { result }) => ({
    ...state,
    reportLoading: false,
    report: result,
  })),
  on(actions.getEmailStatisticsFail, (state) => ({
    ...state,
    reportLoading: false,
  })),
  on(actions.reset, () => ({
    ...initialState,
  }))
);

const selectFeature =
  createFeatureSelector<StatisticsModuleState>("statistics");
const getState = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.emailEvents
);

export const getCampaigns = createSelector(
  getState,
  (state: StatisticsEmailEventsState) => state.campaigns
);

export const getCampaignsLoading = createSelector(
  getState,
  (state: StatisticsEmailEventsState) => state.campaignsLoading
);

export const getReportResult = createSelector(
  getState,
  (state: StatisticsEmailEventsState) => state.report
);

export const getReportLoading = createSelector(
  getState,
  (state: StatisticsEmailEventsState) => state.reportLoading
);
